import { encode } from "./base64";
import dictionary from "./dictionary.json";

export enum Difficulty {
  Normal,
  Hard,
  UltraHard,
}

export const maxGuesses = 6;

export const dictionarySet: Set<string> = new Set(dictionary);

function mulberry32(a: number) {
  return function () {
    var t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

export function urlParam(name: string): string | null {
  return new URLSearchParams(window.location.search).get(name);
}

export const seed = Number(urlParam("seed"));
const makeRandom = () => (seed ? mulberry32(seed) : () => Math.random());
let random = makeRandom();

export function resetRng(): void {
  random = makeRandom();
}

export function pick<T>(array: Array<T>): T {
  return array[Math.floor(array.length * random())];
}

// https://a11y-guidelines.orange.com/en/web/components-examples/make-a-screen-reader-talk/
export function speak(
  text: string,
  priority: "polite" | "assertive" = "assertive"
) {
  var el = document.createElement("div");
  var id = "speak-" + Date.now();
  el.setAttribute("id", id);
  el.setAttribute("aria-live", priority || "polite");
  el.classList.add("sr-only");
  document.body.appendChild(el);

  window.setTimeout(function () {
    document.getElementById(id)!.innerHTML = text;
  }, 100);

  window.setTimeout(function () {
    document.body.removeChild(document.getElementById(id)!);
  }, 1000);
}

export function ordinal(n: number): string {
  return n + ([, "st", "nd", "rd"][(n % 100 >> 3) ^ 1 && n % 10] || "th");
}

export const englishNumbers =
  "zero one two three four five six seven eight nine ten eleven".split(" ");

export function getChallengeUrl(target: string, author: string): string {
  return (
    window.location.origin +
    window.location.pathname +
    "?challenge=" +
    encode(target) +
    (author ? `&author=${encodeURIComponent(author)}` : "")
  );
}

export function makeMysteryBoard(len: number): string { 
  var board = "";
  //for(var i = 0; i < 6; i++) { 
	for(var j = 0; j < len; j++) {
		var rand = Math.floor(Math.random() * 10);
		if(rand > 8) { 
			board += "⁉️"; 
		}
		else if(rand > 6) { 
			board += "❓"; 
		}
		else if(rand > 3) { 
			board += "❔";
		}
		else { 
			board += "⬜"; 
		}
	}
	//board += "\n"; 
  //}
  return board; 
}
